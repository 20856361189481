.container-001 {
  width: 500px;
  border: 1px solid rgba(255, 102, 0, 1);
  border-radius: 5px;
  /* margin: 0 auto; */
  /* padding: 10px; */
}
.circle-001 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  font-size: 24px;
  color: #000000;
  line-height: 50px;
  text-align: center;
  background: #ffffff;
}
.header-001 {
  /* width: 100%; */
  background-color: rgba(255, 102, 0, 1);
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}
.content-001 {
  margin-top: 10px;
  line-height: 1.6;
}

