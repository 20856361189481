.scroll_button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99;
    width: 50px;
    height: 50px;

    border-radius: 50%;
    background-color: #ff3500;
    ;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.15s ease-in 0s;
    font-size: 18px;
    font-weight: 600;

}

.scroll_button::after {
    content: '';
    border: solid red;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;

    animation: move 0.9s ease infinite;
}

@keyframes move {

    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.4);
    }
}

.scroll_button a {
    text-decoration: none;
    color: #000;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.scroll_button:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
}

.scroll_button:hover a {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}