@import url("https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,600,700|Poppins:200,200i,300,300i,400,500,500i,600,700,800,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,600,700|Poppins:200,200i,300,300i,400,500,500i,600,700,800,900&display=swap");


.testmonial_area {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding: 25px 0 25px 0;
  overflow: hidden;
  background: #fff;

}


@media (max-width: 767px) {

  /* line 2, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
  .testmonial_area {
    padding: 50px 0 50px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  /* line 2, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
  .testmonial_area {
    padding: 100px 0 100px 0;
  }
}


.testmonial_area .single_testmonial {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 60px 80px 85px 80px;
  border: 1px solid rgb(242, 129, 33);
}


@media (max-width: 767px) {

  /* line 17, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
  .testmonial_area .single_testmonial {
    display: block !important;
    padding: 30px;
  }
}

/* line 28, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .single_testmonial .test_thumb {
  margin-right: 25px;
}

@media (max-width: 767px) {

  /* line 28, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
  .testmonial_area .single_testmonial .test_thumb {
    margin-bottom: 50px;
  }
}

/* line 33, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .single_testmonial .test_thumb img {
  max-width: 130px;
}

/* line 38, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .single_testmonial .test_content h4 {
  font-size: 20px;
  font-weight: 600;
  color: #383737;
  margin-bottom: 0;
}

/* line 44, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .single_testmonial .test_content span {
  display: block;
  color: #979797;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 1;
  margin-top: 3px;
}

/* line 54, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .single_testmonial .test_content p {
  font-size: 18px;
  color: #66666f;
  margin-bottom: 0;
  line-height: 35px;
}

@media (max-width: 767px) {

  /* line 54, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
  .testmonial_area .single_testmonial .test_content p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {

  /* line 62, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
  .testmonial_area .single_testmonial .test_content p br {
    display: none;
  }
}

/* line 71, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .textmonial_active .owl-item {
  opacity: .6;
}

/* line 73, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .textmonial_active .owl-item.active {
  opacity: 1;
}


/* line 79, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .owl-carousel .owl-item img {
  display: inline-block;
  width: 130px;
}

/* line 83, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .owl-carousel .owl-nav div.owl-next {
  left: auto;
  right: 40px;
}

/* line 87, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .owl-carousel .owl-nav div {
  left: 0;
  background: #000;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #ddc4c4;
  background: #fff;
  font-size: 14px;
  color: #f39b80 !important;
  left: auto;
  bottom: 30px;
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  top: auto;
  right: 78px;
  font-size: 20px;
  border: none;
  color: #D9D9D9;
  background: transparent;
}

/* line 111, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .owl-carousel .owl-nav div {
  color: #d9d9d9 !important;
}

/* line 113, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .owl-carousel .owl-nav div:hover {
  color: #383737 !important;
  background: transparent;
}

/* line 118, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .owl-carousel .owl-nav div.owl-prev i {
  position: relative;
  right: 1px;
  top: -1px;
}

/* line 124, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .owl-carousel .owl-nav div.owl-next i {
  position: relative;
  right: 0;
  top: -1px;
}

/* line 131, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_testmonial.scss */
.testmonial_area .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: visible;
  -webkit-transform: translate3d(0, 0, 0);
}

.test_content a {
  text-decoration: none;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
}