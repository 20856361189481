
.eventcard{
    width: 370px;
    height: 500px;
    border-radius: 20px;
    overflow: hidden;
    border:  7px solid #cc4925;
    position: relative;
    margin: 0.5rem;
    /* cursor: pointer; */
}
.card-img{
    width: 100%;
    height: 100%;
    border-radius: 7px;
    object-fit: cover;
}
.card-body{
    width: 100%;
    height: 100%;
    top: 0;
    right: -100%;
    opacity: 0%;
    padding: 10px;
    position:absolute;
    background: #1f3d4738;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.5s ease-in-out;
}
.card-title{
    text-transform: uppercase;
    font-size: 2rem;
    cursor: pointer;
    font-weight: 500;
    color: #ffffff;
    transition: all 0.5s ease-in-out;
}
.card-title:hover{
    color: #cc4925;
}
.card-info{
    font-size: 20px;
    line-height: 25px;
    margin: 40px 0;
    font-weight: 400;
    color: #fff;
}
.eventcard:hover .card-body{
    right: 0%;
    opacity: 100%;
    transition: all 0.5s ease-in-out;
}