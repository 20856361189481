* {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.full-screen {
    background: linear-gradient(to bottom, #fd7e14 25%, #fd7e14 25%);
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 0px 60px 0px;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%;
    box-shadow: 
    0 1px 3px rgba(0, 0, 0, 0.1), 
    0 4px 6px rgba(0, 0, 0, 0.1), 
    0 5px 15px rgba(0, 0, 0, 0.1), 
    -3px -3px 5px rgba(0, 0, 0, 0.05); 
    /* background-color: rgb(255, 255, 255); */
}

.main-form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.left-part {
    padding: 20px;
    width: 100%;
}

.main-heading {
    font-size: 1.8rem;
    font-weight: 500;
    color: rgb(255, 125, 14);
    margin-bottom: 30px;
    line-height: 1.2;
    text-align: center;
}

.heading_data {
    margin-bottom: 24px;
}

.line {
    margin-bottom: 30px;
    padding: 0 15px;
}

.details {
    display: flex;
    flex-direction: column;
}

.our-q {
    color: #B3B3B3;
    font-size: 14px;
    margin-bottom: 10px;
}

.ans {
    font-size: 16px;
    color: rgb(24, 25, 27);
    border: none;
    border-bottom: 1px solid #bcb5b5;
    padding: 0 6px;
}

.right-part {
    width: 100%;
    background-image: linear-gradient(0deg, rgb(241, 68, 55) 0%, rgb(237, 91, 13) 99%);
    padding: 20px;
    border-radius: 0 5px 5px 0 ;
    /* text-align: center; */
}

.contact-inf {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}

.rca-logo {
    object-fit: cover;
    max-width: 100%;
    height: auto;
}

.contact {
    color: white;
    font-weight: 500;
    margin-bottom: 20px;
}

.rca-name {
    color: #FFFFFF80;
    font-weight: 300;
    margin-bottom: 20px;
}

.ul11 {
    margin-top: 40px;
}

.dot {
    list-style-type: none;
    color: #FFFFFF80;
    margin: 20px 0px;
    font-size: 1rem;
}

input[type="text"]:focus {
    outline: none;
    border-bottom: 1px solid black;
}

.btn-box {
    width: 100%;
    text-align: center;
}

.submit-btn {
    margin: 10px 0px;
    padding: 10px 24px;
    background-color: rgb(241, 68, 55);
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 300;
    border-radius: 5px;
    width: 100%;
}

/* Responsive styling */
@media screen and (min-width: 768px) {
    .contact-inf {
        padding-top: 100px;
    }

    .main-form {
        flex-direction: row;
    }

    .left-part {
        width: 70%;
        /* padding: 200px; */
       
    }
    /* .btn-box {
       margin-bottom: 200px;
    } */

    .right-part {
        width: 30%;
    }

    .main-heading {
        font-size: 2rem;
        text-align: left;
    }
}

@media screen and (min-width: 1024px) {
    .card {
        flex-direction: row;
        max-width: 75%;
    }

    .main-form {
        flex-direction: row;
    }

    .left-part {
        width: 70%;
        padding: 40px;
    }

    .right-part {
        width: 30%;
    }

    .main-heading {
        font-size: 2.2rem;
    }

    .btn-box {
        width: auto;
    }

    .submit-btn {
        width: auto;
    }
}
