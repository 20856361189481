.single_service {
    padding: 70px 25px 65px 25px;
    border: 1px solid #ffc5c2;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-bottom: 30px;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin: 1rem;
   
}

/* line 21, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_service.scss */
.single_service .service_thumb {
    height: 140px;
    width: 160px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: auto;
}

/* line 29, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_service.scss */
.single_service .service_thumb .service_icon img {
    width: auto;
}

/* line 35, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_service.scss */
.single_service .service_content h3 {
    font-size: 25px;
    font-weight: 600;
    margin-top: 43px;
    margin-bottom: 22px;
}

/* line 41, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_service.scss */
.single_service .service_content p {
    margin-bottom: 0;
}

/* line 45, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_service.scss */
.single_service:hover {
    -webkit-box-shadow: 0px 16px 35px 0px rgba(0, 0, 2, 0.08);
    -moz-box-shadow: 0px 16px 35px 0px rgba(0, 0, 2, 0.08);
    box-shadow: 0px 16px 35px 0px rgba(0, 0, 2, 0.08);
    border: 1px solid transparent;
}

 .single_service {
    margin-bottom: 50px;
}

/* line 29, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_prising.scss */

.service_icon_bg_1 {
    background-image: url(../../../../public/img/service/service_icon_bg_1.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }