
.address-right {

    background-color: #fff;
    -webkit-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
    border-radius: 2rem;
  
    margin-left: 50px;
    margin-right: 50px;

}
.address-info p a {
    color: #999;
  }
  
  .address-info p span {
    display: block;
  }
  
@media screen and (min-width: 601px) {
   
    .address-right {
        background-color: #fff;
        -webkit-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
      }
    
}

.links{
  display: flex;
  justify-content: center;
}

.social1 {
    margin-top: 60px;
    margin-left: 30px;
    margin-bottom: 60px;
  }
  
  .social1 ul li {
    display: inline-block;
     
  }
  

  
  .social1 ul li span {
    font-size: 4rem;
    padding: 4px 5px;
     
    transition: all 0.5s;
  }

  a{
    text-decoration: none;
  }
/* 
  .address-right {
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 13px -1px rgba(0, 0, 0, 0.15);
    border-radius: 2rem;
    margin-left: 20px;
    margin-right: 20px;
    overflow-x: hidden;  
}

.address-info p a {
    color: #999;
}

.address-info p span {
    display: block;
}

.links {
    display: flex;
    justify-content: center;
}

.social1 {
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: 30px;
    overflow-x: auto;  
    white-space: nowrap;  
}

.social1 ul li {
    display: inline-block;
    margin: 0 10px;
}

.social1 ul li span {
    font-size: 2rem;
    padding: 2px 3px;
    transition: all 0.5s;
}

@media screen and (min-width: 601px) {
    .address-right {
        margin-left: 50px;
        margin-right: 50px;
    }

    .social1 {
        margin-top: 60px;
        margin-left: 30px;
        margin-bottom: 60px;
        overflow-x: hidden;  
    }

    .social1 ul li {
        margin: 0 20px;
    }

    .social1 ul li span {
        font-size: 4rem;
        padding: 4px 5px;
    }
} */
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  width: 98% !important;
}

/* .row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  overflow-x: hidden !important;
  overflow-y: hidden !important;
} */