.khamma-form-inputs {
  /* margin: 0.5rem; */
  border-radius: 0.5rem;
  font-size: 1.2rem;
  border: none;
  background-color: #eee;
  padding: 0.5rem 1rem;
}

.row-outer-khamma{
  border-radius: 15px;
  margin: 5rem;
}

.row-khamma{
  display: flex;
  border-radius: 15px;
}
.col-khamma-left{
  border-radius: 15px 0 0 15px;
}
.col-khamma-right{
  border-radius: 0 15px 15px 0;
  
}

/* on small devices */
@media (max-width: 767px) {
  row-outer-khamma{
    margin: 1rem;
  }
  .row-khamma{
    flex-direction: column;
  }
  .col-khamma-left{
    justify-content: center;
    border-radius: 15px 15px 0 0;
  }
  .col-khamma-right{
    border-radius: 0 0 15px 15px;
  }
}

.khamma-button{
  border-radius: 0.5rem;
  font-size: 1.2rem;
  border: 0px;
  background-color: #e9851a;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  transition: all 0.1s ease;
}
.khamma-button:hover{
  transform: scale(1.1);
}
