/* Navbar.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: black;
  padding: 0 50px;
  height: 110px;
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.nav_space {
  height: 110px;
}

.nav_space {
  height: 110px;
}

.logo {
  font-size: 19px;
  font-weight: 500;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo a {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: #f28121;
}

nav .logo img {
  height: 70px;
  margin: 5px 10px;
}

/* Hamburger menu styles */
.menu-toggle {
  display: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.menu-toggle.open {
  transform: rotate(180deg);
}

.HiMenu {
  transform: scale(1.2);
}

.nav-links {
  list-style: none;
  display: flex;
  margin: auto 0px;
  font-size: 15px;
}

.nav-links li {
  font-family: 'Poppins', sans-serif;
  font-size: medium;
  margin-right: 30px;
  /* text-transform: uppercase; */
  transition: all 0.2s ease-in;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.nav-links a {
  text-decoration: none;
  color: black;
}

.nav-links li:hover {
  color: #f28121;
}




@media (max-width: 1024px) {
  nav {
      height: 90px;
  }

  .logo {
      font-size: 25px;
      letter-spacing: 0px;
  }

  nav .logo img {
      height: 90px;
  }

  .nav-links {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      background-color: #ffffff;
      line-height: 30px;
      margin: -7px 0px;
      padding: 20px 10px;
      position: fixed;
      top: 90px;
      right: 0;
      width: 100%;
      min-height: 50vh;
      text-align: center;
      transform: translateX(100%);
      transition: transform 0.3s ease-out;
      z-index: 999;
      border-radius: 0px 0px 10px 10px;
      opacity: 0.5;
  }

  .nav_space {
      height: 90px;
  }

  .nav-links.show {
      transform: translateX(0);
      opacity: 1;
  }

  .menu-toggle {
      display: flex;
      align-items: center;
  }

  .nav-links {
      letter-spacing: 1px;
      
    }
    
    .nav-links li {
      /* line-height: 100px; */
      font-weight: bolder ;
      color: black;
      margin: 0px 10px;
      border-radius: 10px;
      cursor: pointer;
  }

  
}

@media screen and (max-width: 767px) {
  nav {
      padding: 0 10px;
      height: 69px;
  }

  .nav_space {
      height: 69px;
  }

  .logo {
      font-size: 15px;
      letter-spacing: 0px;
  }

  .menu-toggle,
  .HiMenu {
      font-size: 20px;
  }

  nav .logo img {
      height: 44px;
  }

  .nav-links {
      top: 69px;
  }
}