html{
  color: #909092;;
}

.event_container{
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.service_area {
    padding-top: 241px;
    padding-bottom: 106px;
  }
  
  @media (max-width: 767px) {
  
    /* line 4, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_service.scss */
    .service_area {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
  
    /* line 4, ../../Arafath/CL/january 2020/240. Animal/HTML/scss/_service.scss */
    .service_area {
      padding-top: 100px;
      padding-bottom: 40px;
    }
  }
  
  h1{
    font-size: 4rem !important;
    font-family: "poppins";
    font-weight: 700;
  }